// ----------------------------------------------------------------------

// FIXME check breakpoints effect

const breakpoints = {
  values: {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
    custom: 1100,
  },
};

// const breakpoints = {
//   values: {
//     xs: 0,
//     sm: 600,
//     md: 900,
//     lg: 1200,
//     xl: 1536,
//   },
// };

export default breakpoints;
