import { TableRow, TableCell, BoxProps } from '@mui/material';
import useLocales from 'src/hooks/useLocales';
import FilterContent from '../FilterContent';

type Props = BoxProps & {
  title?: string;
  dense?: boolean;
};

export default function TableFilterSelect({ title, dense, ...other }: Props) {
  const { translate } = useLocales();

  return (
    <TableRow>
      <TableCell colSpan={12}>
        <FilterContent
          title={title ?? translate('table.content.selectFilter')}
          dense={dense}
          {...other}
        />
      </TableCell>
    </TableRow>
  );
}
