import { configureStore } from '@reduxjs/toolkit';
import userRolesReducer from './user-roles';
import userOrganizersReducer from './user-organizers';
import locationsReducer from './locations';
import { userApi } from 'src/redux/api/userAPI';

const store = configureStore({
  reducer: {
    userRoles: userRolesReducer,
    userOrganizers: userOrganizersReducer,
    locations: locationsReducer,
    [userApi.reducerPath]: userApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(userApi.middleware),
});

export type GetState = typeof store.getState;
export type RootState = ReturnType<GetState>;

export type AppDispatch = typeof store.dispatch;

export default store;
