import { styled } from '@mui/material/styles';
import { Typography, Box, BoxProps } from '@mui/material';
import Image from './Image';

const RootStyle = styled(Box)(({ theme }) => ({
  height: '100%',
  rowGap: 8,
  padding: theme.spacing(8, 2),
}));

interface Props extends BoxProps {
  title: string;
  dense?: boolean;
}

export default function FilterContent({ title, dense, ...other }: Props) {
  return (
    <RootStyle minHeight={260}>
      <Box
        position="absolute"
        bottom={dense ? 54 : 64}
        left="50%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          transform: 'translateX(-50%)',
        }}
        {...other}
      >
        <Image
          disabledEffect
          visibleByDefault
          alt="empty content"
          src={'/assets/illustrations/illustration_filter_content.svg'}
          sx={{ maxWidth: 140 }}
        />

        <Typography variant="h5" gutterBottom whiteSpace="nowrap">
          {title}
        </Typography>
      </Box>
    </RootStyle>
  );
}
