import { ReactNode, useEffect, useMemo } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/redux/hook';
import { userRolesActions } from 'src/redux/user-roles';
import { useGetRolesQuery } from 'src/redux/api/userAPI';

type Props = {
  permission: number;
  children: ReactNode;
};

export default function CurrentRoleSettings({ permission, children }: Props) {
  const { user } = useAuth();

  const dispatch = useDispatch();
  const { setCurrentUserRole } = userRolesActions;

  const currentRole = useAppSelector((state) => state.userRoles.currentUserRole);
  const currentOrganizer = useAppSelector((state) => state.userOrganizers.currentUserOrganizer);

  const { data: userRoles } = useGetRolesQuery(
    {
      userId: user?.id,
      organizerId: currentOrganizer?.id,
    },
    { skip: !user || !currentOrganizer }
  );

  const hasPermissionToThisPageRoles = useMemo(
    () =>
      userRoles?.filter(
        (userRole) => userRole.permissions.includes(permission) || userRole.isOrganizer
      ),
    [permission, userRoles]
  );

  useEffect(() => {
    if (currentRole && !!hasPermissionToThisPageRoles?.length) {
      if (!hasPermissionToThisPageRoles.find((role) => role.id === currentRole.id)) {
        dispatch(setCurrentUserRole(hasPermissionToThisPageRoles[0]));

        localStorage.setItem('currentAccountId', hasPermissionToThisPageRoles[0].id.toString());
      }
    }
  }, [currentRole, dispatch, hasPermissionToThisPageRoles, setCurrentUserRole]);

  return <>{children}</>;
}
