import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import useLocales from 'src/hooks/useLocales';
import Image from 'src/components/Image';
import { useAppSelector } from 'src/redux/hook';
import { NotFoundType } from 'src/@types/types';
import { notFoundRecordConstants } from 'src/common/constants/not-found-record';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

type Props = {
  id?: string;
  type: NotFoundType;
};

export default function NotFoundRecord({ id, type }: Props) {
  const { translate, currentLang } = useLocales();

  const currentOrganizer = useAppSelector((state) => state.userOrganizers.currentUserOrganizer);

  return (
    <ContentStyle sx={{ textAlign: 'center' }}>
      <Image
        visibleByDefault
        disabledEffect
        src="/assets/illustrations/illustration_404.svg"
        alt="login"
        sx={{ maxWidth: 180, margin: 'auto' }}
      />

      <Typography variant="h4" gutterBottom>
        {translate(`notFoundRecord.header.${type}`)}
      </Typography>

      <Typography sx={{ color: 'text.secondary' }}>
        {translate('notFoundRecord.description', {
          id,
          type: notFoundRecordConstants[currentLang.value][type],
          organizerName: currentOrganizer?.name,
        })}
      </Typography>
    </ContentStyle>
  );
}
