export const getFilteredRoutes = (params: {
  routes: any[];
  permissions: number[];
  isOrganizer?: boolean;
}) => {
  const { routes, permissions, isOrganizer } = params;

  let result = [];

  for (const route of routes) {
    if (!route.disabledFeature && route.children) {
      route.children = getFilteredRoutes({ routes: route.children, permissions, isOrganizer });

      result.push(route);
    } else {
      if (
        !route.disabledFeature &&
        (!route.permission || isOrganizer || permissions.includes(route.permission))
      ) {
        result.push(route);
      }
    }
  }

  return result;
};
