import { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import Editor, { EditorProps } from '../editor';
import useLocales from 'src/hooks/useLocales';

interface Props extends EditorProps {
  name: string;
  placeholder: string;
  video?: boolean;
  dynamicContent?: boolean;
  resetContent?: boolean;
  onReset?: VoidFunction;
}

export default function RHFEditor({ name, placeholder, helperText, ...other }: Props) {
  const {
    control,
    watch,
    setValue,
    formState: { isSubmitSuccessful },
  } = useFormContext();

  const { currentLang } = useLocales();

  const values = watch();

  useEffect(() => {
    if (values[name] === '<p><br></p>') {
      setValue(name, '', {
        shouldValidate: !isSubmitSuccessful,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang.value, isSubmitSuccessful, name, setValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Editor
          id={name}
          value={field.value}
          placeholder={placeholder}
          onChange={field.onChange}
          error={!!error}
          helperText={
            (!!error || helperText) && (
              <FormHelperText style={{ marginTop: 8 }} error={!!error} sx={{ px: 1.9 }}>
                {error ? error?.message : helperText}
              </FormHelperText>
            )
          }
          {...other}
        />
      )}
    />
  );
}
