import { useState } from 'react';
import { TableEnum } from 'src/components/table/type';

// ----------------------------------------------------------------------

export type UseTableProps = {
  dense: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  order: 'asc' | 'desc';
  orderBy: string;
  //
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  onSelectRow: (id: string) => void;
  onSelectAllRows: (checked: boolean, newSelecteds: string[]) => void;
  //
  onSort: (id: string) => void;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDense: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export type Props = {
  defaultDense: string | null;
  defaultOrder?: OrderEnum;
  defaultOrderBy?: string;
  defaultSelected?: number[];
  defaultRowsPerPage?: number;
  defaultCurrentPage?: number;
};

export enum OrderEnum {
  ASC = 'asc',
  DESC = 'desc',
}

export default function useTable(props?: Props) {
  const [dense, setDense] = useState(
    props?.defaultDense ? (props.defaultDense === 'dense' ? true : false) : true
  );

  const [orderBy, setOrderBy] = useState(props?.defaultOrderBy || 'name');

  const [order, setOrder] = useState<'asc' | 'desc'>(props?.defaultOrder || 'asc');

  const [page, setPage] = useState(props?.defaultCurrentPage || 0);

  const [rowsPerPage, setRowsPerPage] = useState(props?.defaultRowsPerPage || 10);

  const [selected, setSelected] = useState<number[]>(props?.defaultSelected || []);

  const onSort = (id: string, table: TableEnum) => {
    const isAsc = orderBy === id && order === 'asc';

    if (id !== '') {
      switch (table) {
        case TableEnum.USERS:
          localStorage.setItem('userOrder', isAsc ? 'desc' : 'asc');
          localStorage.setItem('userOrderBy', id);
          break;
        case TableEnum.USER_HISTORY:
          localStorage.setItem('userHistoryOrder', isAsc ? 'desc' : 'asc');
          localStorage.setItem('userHistoryOrderBy', id);
          break;
        case TableEnum.EVENTS:
          localStorage.setItem('eventOrder', isAsc ? 'desc' : 'asc');
          localStorage.setItem('eventOrderBy', id);
          break;
        case TableEnum.EVENT_HISTORY:
          localStorage.setItem('eventHistoryOrder', isAsc ? 'desc' : 'asc');
          localStorage.setItem('eventHistoryOrderBy', id);
          break;
        case TableEnum.EVENT_LIMIT_MANAGEMENT:
          localStorage.setItem('eventLimitManagementOrder', isAsc ? 'desc' : 'asc');
          localStorage.setItem('eventLimitManagementOrderBy', id);
          break;
        case TableEnum.STATISTICS_SALES:
          localStorage.setItem('statisticsSalesOrder', isAsc ? 'desc' : 'asc');
          localStorage.setItem('statisticsSalesOrderBy', id);
          break;
        case TableEnum.BOXOFFICE_LISTING:
          localStorage.setItem('boxOfficeEventListOrder', isAsc ? 'desc' : 'asc');
          localStorage.setItem('boxOfficeEventListOrderBy', id);
          break;
        case TableEnum.SETTLEMENT:
          localStorage.setItem('settlementEventListOrder', isAsc ? 'desc' : 'asc');
          localStorage.setItem('settlementEventListOrderBy', id);
          break;
        case TableEnum.GUEST_INVITE:
          localStorage.setItem('guestInviteOrder', isAsc ? 'desc' : 'asc');
          localStorage.setItem('guestInviteOrderBy', id);
          break;
        case TableEnum.COUPON_LIST:
          localStorage.setItem('couponListOrder', isAsc ? 'desc' : 'asc');
          localStorage.setItem('couponListOrderBy', id);
          break;
        case TableEnum.STATISTICS_COUPON:
          localStorage.setItem('couponStatisticsOrder', isAsc ? 'desc' : 'asc');
          localStorage.setItem('couponStatisticsOrderBy', id);
          break;
        case TableEnum.CUSTOMIZED_STATISTICS:
          localStorage.setItem('customizedStatisticsOrder', isAsc ? 'desc' : 'asc');
          localStorage.setItem('customizedStatisticsOrderBy', id);
          break;
        case TableEnum.GUEST_LIMIT_USER_LIST:
          localStorage.setItem('guestLimitUserListOrder', isAsc ? 'desc' : 'asc');
          break;
        case TableEnum.GUEST_LIMIT_EVENT_LIST:
          localStorage.setItem('guestLimitEventListOrder', isAsc ? 'desc' : 'asc');
          localStorage.setItem('guestLimitEventListOrderBy', id);
          break;
      }

      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const onSelectRow = (id: number) => {
    const selectedIndex = selected.indexOf(id);

    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const onSelectAllRows = (checked: boolean, newSelecteds: number[]) => {
    if (checked) {
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const onChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('tableDense', event.target.checked ? 'dense' : 'undense');
    setDense(event.target.checked);
  };

  return {
    dense,
    order,
    page,
    setPage,
    orderBy,
    rowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeDense,
    onChangeRowsPerPage,
  };
}

// ----------------------------------------------------------------------

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: 'asc' | 'desc',
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function emptyRows(page: number, rowsPerPage: number, arrayLength: number) {
  return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}
