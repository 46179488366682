import { UserRoleState } from '../@types/store';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { userApi } from 'src/redux/api/userAPI';
import { getPermissionsByRoles } from 'src/utils/permissions-by-roles';

const initialState: UserRoleState = {
  currentUserRole: null,
  previousRoles: [],
  permissionsByRoles: [],
};

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setCurrentUserRole(state: UserRoleState, action) {
      state.currentUserRole = action.payload;
    },
    setPreviousRoles(state: UserRoleState, action) {
      state.previousRoles = action.payload;
    },
    setPermissionsByRoles(state: UserRoleState, action) {
      state.permissionsByRoles = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<UserRoleState>) => {
    builder.addMatcher(userApi.endpoints.getRoles.matchFulfilled, (state, action) => {
      const savedRoleId = localStorage.getItem('currentAccountId');

      const [firstRole] = action.payload;

      const currentRole =
        (savedRoleId && action.payload.find((role) => role.id === +savedRoleId)) || firstRole;

      currentRole && localStorage.setItem('currentAccountId', currentRole.id.toString());

      state.previousRoles = action.payload;
      state.currentUserRole = currentRole ? currentRole : null;

      const permissionsByRoles = getPermissionsByRoles(action.payload);

      state.permissionsByRoles = permissionsByRoles;
    });
  },
});

export const userRolesActions = roleSlice.actions;

export default roleSlice.reducer;
