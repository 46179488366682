import { useFormContext, Controller } from 'react-hook-form';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import useLocales from '../../hooks/useLocales';
import { utcToZonedTime } from 'date-fns-tz';
import { getDateAdapterLocale, getDateLocaleText } from 'src/utils/date-locale';

type IProps = {
  name: string;
  errorMsg: string;
  testId?: string;
  size?: 'small' | 'medium';
};

type Props = IProps & DateTimePickerProps<Date>;

export default function RHFDateTimePicker({
  name,
  errorMsg,
  testId,
  size,
  slotProps,
  ...other
}: Props) {
  const { control } = useFormContext();
  const { currentLang } = useLocales();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...fieldProps }, fieldState: { error } }) => (
        <LocalizationProvider
          adapterLocale={getDateAdapterLocale(currentLang.value)}
          dateAdapter={AdapterDateFns}
          localeText={
            getDateLocaleText(currentLang.value).components.MuiLocalizationProvider.defaultProps
              .localeText
          }
        >
          <DateTimePicker
            {...fieldProps}
            ampm={false}
            defaultValue={null}
            value={value ? utcToZonedTime(value.setSeconds(0), 'UTC') : null}
            format={currentLang.value === 'hu' ? 'yyyy.MM.dd. HH:mm' : undefined}
            views={['year', 'month', 'day', 'hours', 'minutes']}
            timeSteps={{ hours: 1, minutes: 1 }}
            slotProps={{
              actionBar: {
                actions: ['clear', 'accept'],
              },
              textField: {
                error: !!errorMsg || !!error?.message,
                helperText: errorMsg ? errorMsg : error?.message,
                ...slotProps?.textField,
                inputProps: { 'data-testid': testId },
                size,
              },
              popper: {
                placement: 'auto-end',
              },
            }}
            {...other}
          />
        </LocalizationProvider>
      )}
    />
  );
}
