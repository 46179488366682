import '../../utils/highlight';
import ReactQuill from 'react-quill';
import { EditorProps } from './types';
import { StyledEditor } from './styles';
import EditorToolbar, { formats } from './EditorToolbar';
import { useMemo, useRef } from 'react';

const getModules = (videoPlaceholderHandler: VoidFunction, id: string) => ({
  toolbar: {
    container: id,
    handlers: {
      video: videoPlaceholderHandler,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
  syntax: true,
  clipboard: {
    matchVisual: false,
    pastePlain: true,
  },
});

export default function Editor({
  id = 'quill',
  error,
  value,
  placeholder,
  video,
  dynamicContent,
  simple = false,
  helperText,
  readOnly,
  sx,
  onChange,
  ...other
}: EditorProps) {
  const quillRef = useRef<ReactQuill | null>(null);

  const handleInsertVideoPlaceholder = () => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      const videoAlreadyInserted = editor.getText().includes(`%% YTvideo %%`);

      if (!videoAlreadyInserted) {
        const cursorPosition = editor.getSelection()?.index || 0;
        editor.insertText(cursorPosition, `%% YTvideo %%`);
      }
    }
  };

  const handleInsertPlaceholder = (placeholder: string) => {
    const editor = quillRef.current?.getEditor();

    if (editor) {
      editor.focus();

      const cursorPosition = editor.getSelection()?.index || 0;

      editor.insertText(cursorPosition, placeholder);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const modules = useMemo(() => getModules(handleInsertVideoPlaceholder, `#${id}`), [id]);

  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
        }}
      >
        <EditorToolbar
          id={id}
          isSimple={simple}
          video={video}
          dynamicContent={dynamicContent}
          readOnly={readOnly}
          onInsert={handleInsertPlaceholder}
          onReset={other.onReset}
        />

        <ReactQuill
          ref={quillRef}
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder={placeholder}
          readOnly={readOnly}
          {...other}
        />
      </StyledEditor>

      {helperText && helperText}
    </>
  );
}
