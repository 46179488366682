import { lazy } from 'react';

export const lazyWithRetry = <T>(
  componentImport: () => Promise<{
    default: (props: T) => JSX.Element;
  }>
) =>
  lazy(async () => {
    const pageHasRefreshed = JSON.parse(sessionStorage.getItem('pageHasRefreshed') || 'false');

    try {
      const component = await componentImport();

      if (component === undefined && !pageHasRefreshed) {
        sessionStorage.setItem('pageHasRefreshed', 'true');
        window.location.reload();
      }

      return component;
    } catch (error) {
      console.error('Error', error);

      if (!pageHasRefreshed) {
        sessionStorage.setItem('pageHasRefreshed', 'true');
        window.location.reload();
      }

      throw error;
    }
  });
