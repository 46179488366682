import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Button, Link, Stack } from '@mui/material';
// @types
import { NavSectionProps } from '../type';
// hooks
import useLocales from 'src/hooks/useLocales';

import NavList from './NavList';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

function NavSectionHorizontal({ navConfig }: NavSectionProps) {
  const { translate } = useLocales();

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ bgcolor: 'background.neutral', borderRadius: 1, px: 0.5 }}
      >
        <Stack direction="row" sx={{ ...hideScrollbar, py: 1 }}>
          {navConfig.map((group) => (
            <Stack key={group.subheader} direction="row" flexShrink={0}>
              {group.items.map((list) => (
                <NavList
                  key={list.title + list.path}
                  data={list}
                  depth={1}
                  hasChildren={!!list.children}
                />
              ))}
            </Stack>
          ))}
        </Stack>
        <Stack justifyContent="center" alignItems="end" spacing={5}>
          <Link component={RouterLink} to={PATH_DASHBOARD.general.recommendation}>
            <Button fullWidth variant="contained">
              {translate('recommendation.menuButton')}
            </Button>
          </Link>
        </Stack>
      </Stack>
    </>
  );
}

export default memo(NavSectionHorizontal);
