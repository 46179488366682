import { Stack } from '@mui/material';
import { useAppSelector } from 'src/redux/hook';
import RoleSelect from 'src/components/AccountSelect';
import { RoleData } from 'src/@types/user';

type Props = {
  hasPermissionToThisPageRoles: RoleData[];
};

export default function OfflineTicketTableToolbar({ hasPermissionToThisPageRoles }: Props) {
  const currentRole = useAppSelector((state) => state.userRoles.currentUserRole);
  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
      <RoleSelect currentRole={currentRole} roles={hasPermissionToThisPageRoles} />
    </Stack>
  );
}
