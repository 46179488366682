import { OptionsObject, useSnackbar } from 'notistack';
import { ReactNode, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/hook';
// components
import { PATH_DASHBOARD } from 'src/routes/paths';
import { socket } from 'src/services/socket/socket.io';
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

type EventGuardProps = {
  children: ReactNode;
};

export default function EventGuard({ children }: EventGuardProps) {
  const { pathname } = useLocation();

  const { id } = useParams();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const { user } = useAuth();

  const currentOrganizer = useAppSelector((state) => state.userOrganizers.currentUserOrganizer);

  if (id && pathname === PATH_DASHBOARD.event.edit(id) && isLoading) {
    const roomName = `organizer:${currentOrganizer?.id}-event:${id}`;

    socket.emit('getRoomState', roomName);

    socket.once('roomState', async ({ roomName, isEmpty }) => {
      if (isEmpty) {
        socket.emit('joinRoom', {
          newRoom: `organizer:${currentOrganizer?.id}-event:${id}-user:${user?.id}`,
        });
        setIsLoading(false);
      } else {
        const splittedRoomName = roomName.split(':');
        const id = splittedRoomName[splittedRoomName.length - 1];

        let message: string = '';

        if (user?.id !== +id) {
          try {
            const {
              data: { firstName, lastName },
            } = await axios.get(`user/${id}`);

            message = translate('eventNewEdit.snackbarMsg.preventMultipleEditEventsByAnotherUser', {
              nameEn: `${firstName} ${lastName}`,
              naemHu: `${lastName} ${firstName}`,
            });
          } catch (error) {
            console.error(error);
            message = translate('eventNewEdit.snackbarMsg.preventMultipleEditEvents');
          }
        } else {
          message = translate('eventNewEdit.snackbarMsg.preventMultipleEditEvents');
        }

        const snackBarProps: OptionsObject = {
          variant: 'error',
          autoHideDuration: 4000,
        };

        enqueueSnackbar(message, snackBarProps);

        setError(true);
        setIsLoading(false);
      }
    });
  }

  if (isLoading) <LoadingScreen />;

  if (error) {
    return <Navigate to={PATH_DASHBOARD.event.list} />;
  } else {
    return <>{children}</>;
  }
}
