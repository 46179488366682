// @mui
import { IconButton } from '@mui/material';
//
import MenuPopover from '../MenuPopover';
import IconifyIcon from '../icons/IconifyIcon';
import { IconifyIconEnum } from 'src/@types/icon';

// ----------------------------------------------------------------------

type Props = {
  actions: React.ReactNode;
  open?: HTMLElement | null;
  color?: string;
  width?: number;
  iconSize?: number;
  hasPermissionToEdit?: boolean;
  onClose?: VoidFunction;
  onOpen?: (event: React.MouseEvent<HTMLElement>) => void;
};

export default function TableMoreMenu({
  actions,
  open,
  color = '',
  width = 220,
  iconSize = 20,
  hasPermissionToEdit = true,
  onClose,
  onOpen,
}: Props) {
  const DISABLED_LIGHT = '#999999';

  return (
    <>
      <IconButton onClick={onOpen} disabled={!hasPermissionToEdit}>
        <IconifyIcon
          icon={IconifyIconEnum.More}
          sx={{
            width: iconSize,
            height: iconSize,
            color: !hasPermissionToEdit ? DISABLED_LIGHT : color,
          }}
        />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={onClose}
        onClick={(event) => event.stopPropagation()}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -1,
          width,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        {actions}
      </MenuPopover>
    </>
  );
}
