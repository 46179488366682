// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  children: React.ReactNode;
};

type Props = IProps & TextFieldProps;

export default function RHFSelect({ name, children, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => (
        <TextField
          {...fieldProps}
          inputRef={ref}
          select
          value={fieldProps.value === 0 ? '' : fieldProps.value}
          fullWidth
          error={!!error && !fieldProps.value}
          helperText={!!error && !fieldProps.value ? error.message : ''}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}
