import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { StyledEditorToolbar } from './styles';
import { MouseEventHandler, useState } from 'react';
import useLocales from 'src/hooks/useLocales';
import { Language } from 'src/@types/location';
import { DynamicContentEn, DynamicContentHu } from 'src/@types/guests';
import useResponsive from 'src/hooks/useResponsive';
import IconifyIcon from '../icons/IconifyIcon';
import { IconifyIconEnum } from 'src/@types/icon';

const HEADINGS = ['Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5', 'Heading 6'];

export const formats = [
  // 'align',
  // 'background',
  // 'blockquote',
  'bold',
  'bullet',
  // 'code',
  // 'code-block',
  // 'color',
  // 'font',
  // 'formula',
  // 'header',
  // 'image',
  // 'indent',
  'italic',
  'link',
  'list',
  // 'script',
  // 'size',
  'strike',
  // 'table',
  'underline',
  'video',
];

type EditorToolbarProps = {
  id: string;
  isSimple?: boolean;
  video?: boolean;
  dynamicContent?: boolean;
  readOnly?: boolean;
  onInsert: (placeholder: string) => void;
  onReset?: VoidFunction;
};

export default function EditorToolbar({
  id,
  isSimple,
  video,
  dynamicContent,
  readOnly,
  onInsert,
  onReset,
  ...other
}: EditorToolbarProps) {
  const { translate, currentLang } = useLocales();

  const theme = useTheme();

  const isMobile = useResponsive('down', 'sm');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [copiedDynamicContent, setCopiedDynamicContent] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    !readOnly && setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInsertDynamicContent: MouseEventHandler<HTMLLIElement> | undefined = (event) => {
    const value = event.currentTarget?.getAttribute('value');

    handleClose();

    setTimeout(() => {
      onInsert(value ?? '');
    }, 0);
  };

  const handleCopyDynamicContent = (dynamicContent: string) => {
    navigator.clipboard.writeText(dynamicContent);

    setCopiedDynamicContent(true);
    setTimeout(() => setCopiedDynamicContent(false), 2000);
  };

  return (
    <StyledEditorToolbar {...other}>
      <div id={id}>
        {!isSimple && (
          <div className="ql-formats">
            <select className="ql-header" defaultValue="">
              {HEADINGS.map((heading, index) => (
                <option key={heading} value={index + 1}>
                  {heading}
                </option>
              ))}
              <option value="">Normal</option>
            </select>
          </div>
        )}

        <div className="ql-formats">
          <button type="button" className="ql-bold" />
          <button type="button" className="ql-italic" />
          <button type="button" className="ql-underline" />
          <button type="button" className="ql-strike" />
        </div>

        {!isSimple && (
          <div className="ql-formats">
            <select className="ql-color" />
            <select className="ql-background" />
          </div>
        )}

        <div className="ql-formats">
          <button type="button" className="ql-list" value="ordered" />
          <button type="button" className="ql-list" value="bullet" />
          {!isSimple && <button type="button" className="ql-indent" value="-1" />}
          {!isSimple && <button type="button" className="ql-indent" value="+1" />}
        </div>

        {!isSimple && (
          <div className="ql-formats">
            <button type="button" className="ql-script" value="super" />
            <button type="button" className="ql-script" value="sub" />
          </div>
        )}

        {!isSimple && (
          <div className="ql-formats">
            <button type="button" className="ql-code-block" />
            <button type="button" className="ql-blockquote" />
          </div>
        )}

        {!isSimple && (
          <div className="ql-formats">
            <select className="ql-align" />
          </div>
        )}

        <div className="ql-formats">
          <button type="button" className="ql-link" />
          {video && <button type="button" className="ql-video" />}
        </div>

        <div className="ql-formats">
          {!isSimple && <button type="button" className="ql-formula" />}
          <button type="button" className="ql-clean" />
        </div>

        {dynamicContent && (
          <Box
            style={{
              display: 'inline-block',
              marginBottom: -9,
              padding: '2px 8px',
              border: `0.5px solid ${theme.palette.text.secondary}`,
              borderColor: 'text.secondary',
              borderRadius: 4,
              marginTop: isMobile ? 4 : 0,
              marginLeft: isMobile ? 4 : 0,
              backgroundColor: theme.palette.grey[5008],
            }}
          >
            <Button
              variant="outlined"
              disableRipple
              style={{
                width: 'fit-content',
                color: anchorEl ? theme.palette.primary.main : theme.palette.text.primary,
              }}
              onClick={handleClick}
            >
              <Typography variant="subtitle2" whiteSpace="nowrap">
                {translate('guestSettings.email.content.toolbar.dynamicContent')}
              </Typography>
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {Object.values(
                currentLang.value === Language.HU ? DynamicContentHu : DynamicContentEn
              ).map((dynamicContent) => (
                <MenuItem
                  key={dynamicContent}
                  value={dynamicContent}
                  onClick={handleInsertDynamicContent}
                >
                  <Stack direction="row" justifyContent="space-between" spacing={1} width="100%">
                    <Typography variant="inherit">{dynamicContent}</Typography>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      columnGap={1}
                    >
                      <Tooltip
                        enterTouchDelay={0}
                        arrow
                        title={
                          !copiedDynamicContent
                            ? translate('guestSettings.email.content.toolbar.copy')
                            : translate('guestSettings.email.content.toolbar.copied')
                        }
                        placement="top"
                      >
                        <IconButton
                          sx={{
                            width: 24,
                            paddingX: 0.5,
                            border: (theme) => `0.5px solid ${theme.palette.text.secondary}`,
                            borderRadius: 0.5,
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCopyDynamicContent(dynamicContent);
                          }}
                        >
                          <IconifyIcon icon={IconifyIconEnum.Copy} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        )}

        {onReset && (
          <Box
            style={{
              display: 'inline-block',
              marginBottom: -9,
              padding: '2px 8px',
              border: `0.5px solid ${theme.palette.text.secondary}`,
              borderColor: 'text.secondary',
              borderRadius: 4,
              marginTop: isMobile ? 4 : 0,
              marginLeft: isMobile ? 4 : 16,
              backgroundColor: theme.palette.grey[5008],
            }}
          >
            <Button
              variant="outlined"
              disableRipple
              style={{
                width: 'fit-content',
              }}
              onClick={onReset}
            >
              <Typography variant="subtitle2" whiteSpace="nowrap">
                {translate('guestSettings.email.content.toolbar.reset')}
              </Typography>
            </Button>
          </Box>
        )}
      </div>
    </StyledEditorToolbar>
  );
}
