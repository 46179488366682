// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography, Avatar, Tooltip } from '@mui/material';
// hooks
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined;
};

export default function NavbarAccount({ isCollapse }: Props) {
  const { currentLang } = useLocales();

  const { user } = useAuth();

  const tooLongEmailAddress = user?.email.length && user.email.length > 22;

  const modifiedUserEmail = tooLongEmailAddress ? user.email.substring(0, 20) + '...' : user?.email;

  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <Box display="flex">
          <Avatar // FIXME same as in the AccountPopover
            src={'user?.photoURL'}
            alt={
              currentLang.value === 'hu'
                ? `${user?.lastName} ${user?.firstName}`
                : `${user?.firstName} ${user?.lastName}`
            }
          />

          <Box
            sx={{
              ml: 2,
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.shorter,
                }),
              ...(isCollapse && {
                ml: 0,
                width: 0,
              }),
            }}
          >
            <Typography variant="subtitle2" noWrap>
              {currentLang.value === 'hu'
                ? `${user?.lastName} ${user?.firstName}`
                : `${user?.firstName} ${user?.lastName}`}
            </Typography>
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={15000}
              title={tooLongEmailAddress ? user?.email : ''}
              placement="top"
            >
              <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                {modifiedUserEmail}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
      </RootStyle>
    </Link>
  );
}
