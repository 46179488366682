import { io } from 'socket.io-client';

export const socket = io(process.env.REACT_APP_HOST_API_KEY || '', {
  transports: ['websocket'],
});

socket.on('connect', () => {
  console.log('Connected');
});

socket.on('error', (error) => {
  console.error(error);
});
