import {
  format,
  getTime,
  formatDistanceToNow,
  parse,
  isSameYear,
  isSameMonth,
  differenceInHours,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { hu } from 'date-fns/locale';

// ----------------------------------------------------------------------

export function fDateEn(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fDateHu(date: Date | string | number) {
  return format(new Date(date), 'yyyy.MM.dd.');
}

export function fLongDateHu(date: Date | string | number) {
  return format(new Date(date), 'yyyy. MMMM dd.', { locale: hu });
}

export function fShortDateHu(date: Date | string | number) {
  return format(new Date(date), 'yyyy. MMM dd.', { locale: hu });
}

export function fShortDateWithTimeHu(date: Date | string | number) {
  return format(new Date(date), 'yyyy. MMM dd. HH:mm', { locale: hu });
}

export function fShortDayMonthHu(date: Date | string | number) {
  return format(new Date(date), 'MMM dd.', { locale: hu });
}

export function fLongDateEn(date: Date | string | number) {
  return format(new Date(date), 'MMMM dd, yyyy');
}

export function fShortDateEn(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy');
}

export function fShortDateWithTimeEn(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fShortDayMonthEn(date: Date | string | number) {
  return format(new Date(date), 'dd MMM ');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffixEn(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm');
}

export function fTime(date: Date | string | number) {
  return format(new Date(date), 'HH:mm');
}

export function fTimeString(date: string) {
  return parse(date, 'HH:mm', new Date());
}

export function fDateTimeSuffixHu(date: Date | string | number) {
  return format(new Date(date), 'yyyy.MM.dd. HH:mm');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fLinkDate(date: Date | string | number) {
  return format(new Date(date), 'yyyyMMdd');
}

export function fImgDate(date: Date | string | number) {
  return format(new Date(date), 'yyyyMMddHHmmss');
}

export function fDownloadedFileDate() {
  return format(new Date(), 'yyyy-MM-dd_HH_mm_ss');
}

export function fEventDateWithTextHu(eventStartDate: Date | number, eventEndDate: Date | number) {
  if (differenceInHours(eventEndDate, eventStartDate) <= 24) {
    return format(eventStartDate, 'yyyy. MMMM d. HH:mm', {
      locale: hu,
    });
  }

  if (!isSameYear(eventStartDate, eventEndDate)) {
    const formattedEventStartDate = format(eventStartDate, 'yyyy. MMMM d.', {
      locale: hu,
    });
    const formattedEventEndDate = format(eventEndDate, 'yyyy. MMMM d.', {
      locale: hu,
    });

    return `${formattedEventStartDate} - ${formattedEventEndDate}`;
  }

  if (!isSameMonth(eventStartDate, eventEndDate)) {
    const formattedEventStartDate = format(eventStartDate, 'yyyy. MMMM d.', {
      locale: hu,
    });
    const formattedEventEndDate = format(eventEndDate, 'MMMM d.', {
      locale: hu,
    });

    return `${formattedEventStartDate} - ${formattedEventEndDate}`;
  }

  const formattedEventStartDate = format(eventStartDate, 'yyyy. MMMM d', {
    locale: hu,
  });
  const formattedEventEndDate = format(eventEndDate, 'd.');

  return `${formattedEventStartDate}-${formattedEventEndDate}`;
}

export function fEventDateWithTextEn(eventStartDate: Date | number, eventEndDate: Date | number) {
  if (differenceInHours(eventEndDate, eventStartDate) <= 24) {
    return format(eventStartDate, 'MMMM d, yyyy HH:mm');
  }

  if (!isSameYear(eventStartDate, eventEndDate)) {
    const formattedEventStartDate = format(eventStartDate, 'MMMM d, yyyy');
    const formattedEventEndDate = format(eventEndDate, 'MMMM d, yyyy');

    return `${formattedEventStartDate} - ${formattedEventEndDate}`;
  }

  if (!isSameMonth(eventStartDate, eventEndDate)) {
    const formattedEventStartDate = format(eventStartDate, 'MMMM d');
    const formattedEventEndDate = format(eventEndDate, 'MMMM d, yyyy');

    return `${formattedEventStartDate}-${formattedEventEndDate}`;
  }

  const formattedEventStartDate = format(eventStartDate, 'MMMM d');
  const formattedEventEndDate = format(eventEndDate, 'd, yyyy');

  return `${formattedEventStartDate}-${formattedEventEndDate}`;
}
