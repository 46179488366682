function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  confirmEmail: path(ROOTS_AUTH, '/confirm-email'),
  confirmInvitation: path(ROOTS_AUTH, '/confirme-invitation'),
  confirmedEmail: path(ROOTS_AUTH, '/confirmed-email'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  resendEmailConfirmation: path(ROOTS_AUTH, '/resend-email-confirmation'),
  emailSent: path(ROOTS_AUTH, '/reset-password/email-sent'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: path(ROOTS_DASHBOARD, '/home'),
  event: {
    root: path(ROOTS_DASHBOARD, '/event'),
    create: path(ROOTS_DASHBOARD, '/event/add'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/event/${id}/edit`),
    list: path(ROOTS_DASHBOARD, '/event/list'),
    limitManagement: path(ROOTS_DASHBOARD, '/event/limit-management'),
    history: path(ROOTS_DASHBOARD, '/event/history'),
  },
  statistics: {
    root: path(ROOTS_DASHBOARD, '/statistics'),
    report: path(ROOTS_DASHBOARD, '/statistics/report'),
    create: path(ROOTS_DASHBOARD, '/statistics/report/add'),
    sales: path(ROOTS_DASHBOARD, '/statistics/sales'),
    salesDetails: (id: string) => path(ROOTS_DASHBOARD, `/statistics/sales/event/${id}/details`),
    customizedStatistics: path(ROOTS_DASHBOARD, '/statistics/customized-statistics'),
    customizedStatisticsCategory: (id: string) =>
      path(ROOTS_DASHBOARD, `/statistics/customized-statistics/${id}/category`),
  },
  finance: {
    root: path(ROOTS_DASHBOARD, '/finance'),
    settlement: path(ROOTS_DASHBOARD, '/finance/settlement'),
    detailedSettlement: (id: string) =>
      path(ROOTS_DASHBOARD, `/finance/settlement/event/${id}/detailed-settlement`),
  },
  marketing: {
    root: path(ROOTS_DASHBOARD, '/marketing'),
    coupon: path(ROOTS_DASHBOARD, '/marketing/coupon'),
    addCoupon: path(ROOTS_DASHBOARD, '/marketing/coupon/add'),
    editCoupon: (id: string) => path(ROOTS_DASHBOARD, `/marketing/coupon/${id}/edit`),
    couponCode: (id: string) => path(ROOTS_DASHBOARD, `/marketing/coupon/${id}/coupon-code`),
    statistics: (id: string) => path(ROOTS_DASHBOARD, `/marketing/coupon/${id}/statistics`),
  },
  guests: {
    root: path(ROOTS_DASHBOARD, '/event/guest'),
    invite: path(ROOTS_DASHBOARD, '/event/guest/invite'),
    inviteStatistics: (id: string) => path(ROOTS_DASHBOARD, `/event/${id}/guest/invite/statistics`),
    guestlist: path(ROOTS_DASHBOARD, '/event/guest/list'),
    guestDetails: (eventId: number, guestId: number) =>
      path(ROOTS_DASHBOARD, `/event/${eventId}/guest/${guestId}`),
    limitUsers: path(ROOTS_DASHBOARD, '/event/guest/limit-management/users'),
    limitEvents: (id: string) =>
      path(ROOTS_DASHBOARD, `/event/guest/limit-management/users/${id}/events`),
    settings: path(ROOTS_DASHBOARD, '/event/guest/settings'),
    replyToConfirmation: path(ROOTS_DASHBOARD, '/event/guest/settings/reply-to-confirmation'),
  },
  boxoffice: {
    root: path(ROOTS_DASHBOARD, '/box-office'),
    list: path(ROOTS_DASHBOARD, '/box-office/events'),
    settings: path(ROOTS_DASHBOARD, '/box-office/settings'),
    offlineTicketTypes: (id: string) =>
      path(ROOTS_DASHBOARD, `/box-office/event/${id}/offline-ticket-types`),
  },
  ntak: {
    attraction: {
      root: path(ROOTS_DASHBOARD, '/ntak/attraction'),
      edit: path(ROOTS_DASHBOARD, '/ntak/attraction/edit'),
      list: path(ROOTS_DASHBOARD, '/ntak/attraction/list'),
    },
    boxoffice: {
      root: path(ROOTS_DASHBOARD, '/ntak/boxoffice'),
      edit: path(ROOTS_DASHBOARD, '/ntak/boxoffice/settings'),
      list: path(ROOTS_DASHBOARD, '/ntak/boxoffice/events'),
      offlineTicketTypes: path(ROOTS_DASHBOARD, '/ntak/boxoffice/offline-ticket-types'),
    },
  },

  account: {
    root: path(ROOTS_DASHBOARD, '/account'),
    profile: path(ROOTS_DASHBOARD, '/account/profile'),
    companyData: path(ROOTS_DASHBOARD, '/account/company-data'),
  },
  invitedUser: {
    root: path(ROOTS_DASHBOARD, '/invited-user'),
    invite: path(ROOTS_DASHBOARD, '/invited-user/invite'),
    list: path(ROOTS_DASHBOARD, '/invited-user/list'),
    history: path(ROOTS_DASHBOARD, '/history'),
  },
  general: {
    offer: path(ROOTS_DASHBOARD, '/offer'),
    recommendation: path(ROOTS_DASHBOARD, '/recommendation'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
