import { styled } from '@mui/material/styles';
import { Typography, Box, BoxProps } from '@mui/material';
import Image from 'src/components/Image';

const RootStyle = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(8, 2),
}));

interface Props extends BoxProps {
  title: string;
  img?: string;
  dense?: boolean;
  hasSummary?: boolean;
}

export default function TableEmptyContent({ title, img, dense, hasSummary, ...other }: Props) {
  return (
    <RootStyle minHeight={260}>
      <Box
        position="absolute"
        bottom={!hasSummary ? (dense ? 36 : 46) : dense ? 72 : 82}
        left="50%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          transform: 'translateX(-50%)',
        }}
        {...other}
      >
        <Image
          disabledEffect
          visibleByDefault
          alt="empty content"
          src={img || '/assets/illustrations/illustration_empty_content.svg'}
          sx={{ width: 160 }}
        />

        <Typography variant="h5" gutterBottom whiteSpace="nowrap">
          {title}
        </Typography>
      </Box>
    </RootStyle>
  );
}
