export const errorMessageHandler = (errorMessage: string) => {
  switch (errorMessage) {
    case 'Invalid login data':
      return 'errorMsg.invalidLoginData';
    case 'prismaErrorCode: P2002':
      return 'errorMsg.emailInUse';
    case 'You must register before login, based on the invitation email sent to you!':
      return 'errorMsg.mustRegisterBeforeLogin';
    case 'You must register based on the invitation email sent to you!':
      return 'errorMsg.mustRegisterBasedInvitation';
    default:
      if (errorMessage?.includes('prismaErrorCode')) {
        return 'errorMsg.databaseError';
      }
      return 'errorMsg.internalError';
  }
};
