// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Radio,
  RadioGroup,
  FormHelperText,
  RadioGroupProps,
  FormControlLabel,
  Box,
} from '@mui/material';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  options: {
    label: string | JSX.Element;
    value: any;
    icon?: JSX.Element;
  }[];
};

type Props = IProps & RadioGroupProps;

export default function RHFRadioGroup({ name, options, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <RadioGroup {...field} row {...other}>
            {options.map((option) => (
              <Box
                key={option.value}
                display="flex"
                alignItems="center"
                columnGap={0.7}
                marginRight={2}
              >
                <FormControlLabel
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                  sx={{ marginRight: 0 }}
                />
                {option.icon && option.icon}
              </Box>
            ))}
          </RadioGroup>

          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}
