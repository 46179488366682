// @mui
import { styled } from '@mui/material/styles';
import { CardActionArea, Switch, Typography } from '@mui/material';
// hooks
import useSettings from '../../../hooks/useSettings';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
}));

// ----------------------------------------------------------------------

export default function SettingLayout() {
  const { translate } = useLocales();

  const { themeLayout, onChangeLayout } = useSettings();

  const isHorizontal = themeLayout === 'vertical';

  return (
    <BoxStyle>
      <Typography variant="body2">{translate('accountPopover.horizontalNav')}</Typography>
      <Switch checked={isHorizontal} onChange={onChangeLayout} />
    </BoxStyle>
  );
}
