import { RoleData } from 'src/@types/user';

export const getPermissionsByRoles = (roles?: RoleData[]) =>
  roles?.reduce<number[]>((userPermissions, role) => {
    role.permissions.forEach((permission) => {
      !userPermissions.includes(permission) && userPermissions.push(permission);
    });

    return userPermissions;
  }, []) || [];
