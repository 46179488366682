export enum Status {
  ACTIVE = 'active',
  ACTIVE_OR_DRAFT = 'active-or-draft',
  ACTIVE_AND_PUBLISHED = 'active-and-published',
  ACTIVE_PUBLISHED_AND_ONSALE = 'active-published-and-on-sale',
  ON_SALE_OR_DRAFT = 'on-sale-or-draft',
  SALES_CLOSED = 'sales-closed',
  PUBLISHED = 'published',
  ARCHIVE = 'archive',
  DRAFT = 'draft',
}

export enum TimePeriod {
  ALL = 'all',
  CURRENT_DAY = 'currentDay',
  CURRENT_WEEK = 'currentWeek',
  PREVIOUS_WEEK = 'previousWeek',
  CURRENT_MONTH = 'currentMonth',
  PREVIOUS_MONTH = 'previousMonth',
  CURRENT_YEAR = 'currentYear',
  PREVIOUS_YEAR = 'previousYear',
  CUSTOMIZED = 'customized',
}

export enum ContractForm {
  COMMISSION = 'comissionContract',
  PERSONAL = 'ownContract',
  UNKNOWN = 'unknown',
}

export type ErrorResponse = {
  statusCode: number;
  error: string;
  method: string;
  path: string;
  timeStamp: string;
};

export enum StatusCode {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  INTERNAL_SERVER = 500,
}

export enum SettlementEventStatus {
  CANCELLED = 'cancelled',
  SALES_IN_PROGRESS = 'sales-in-progress',
  SALES_CLOSED = 'sales-closed',
}

export enum Environment {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  RELEASE = 'release',
  PRODUCTION = 'production',
}

export enum NotFoundType {
  EVENT = 'event',
  COUPON = 'coupon',
  CUSTOMIZED_STATISTICS = 'customizedStatistics',
  GUEST_INVITATION = 'guestInvitation',
}

export enum TimeZone {
  UTC = 'UTC',
  HU = 'Europe/Budapest',
}

export enum Locale {
  HU = 'hu-HU',
}

export type FeatureFlags = {
  [key: string]: { enabled: boolean };
};

export type StatisticsChartPoint = [number, number];

export enum DateConvertType {
  UTC_TO_ZONED_TIME = 'utc-to-zoned-time',
  ZONED_TIME_TO_UTC = 'zoned-time-to-utc',
  HU_ZONED_TIME_TO_UTC = 'hu-zoned-time-to-utc',
}

export type SearchableSelectItems = {
  id: number;
  label: string;
  helperText: string;
};
