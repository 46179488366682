// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  label: string;
  testId?: string;
  placeholder?: string;
};

interface CustomizedAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {}

type Props = IProps & CustomizedAutocompleteProps<string | number, true, false, false>;

export default function RHFAutocomplete({
  name,
  label,
  testId,
  placeholder,
  ...other
}: Omit<Props, 'renderInput'>) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(event, newValue) =>
            setValue(name, newValue, { shouldValidate: true, shouldDirty: true })
          }
          renderInput={(params) => (
            <TextField
              label={label}
              error={!!error}
              helperText={error?.message}
              placeholder={placeholder}
              {...params}
              data-testid={testId}
            />
          )}
          {...other}
        />
      )}
    />
  );
}
