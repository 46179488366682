import * as Yup from 'yup';
import { AnimationEventHandler, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Stack, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PATH_AUTH } from '../../../routes/paths';
import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { errorMessageHandler } from '../../../utils/error-message-handler';
import { OptionsObject, useSnackbar } from 'notistack';
import IconifyIcon from 'src/components/icons/IconifyIcon';
import { IconifyIconEnum } from 'src/@types/icon';

type Props = {
  email?: string;
};

type FormValuesProps = {
  email: string;
  password: string;
  remember: boolean;
};

export default function LoginForm({ email }: Props) {
  const { login } = useAuth();
  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const [hasSavedLoginData, setHasSavedLoginData] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(`${translate('login.errorMsg.validEmail')}`)
      .required(
        `${translate('login.errorMsg.required', {
          fieldNameHu: 'Email cím',
          fieldNameEn: 'Email',
        })}`
      )
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
        translate('statistics.sales.report.form.errorMsg.validEmail')
      )
      .matches(
        /^[^\x80-\xFF]+$/,
        translate('statistics.sales.report.form.errorMsg.noAccentedChars')
      ),
    password: Yup.string().required(
      `${translate('login.errorMsg.required', {
        fieldNameHu: 'Jelszó',
        fieldNameEn: 'Password',
      })}`
    ),
  });

  const defaultValues = {
    email: email ? email : '',
    password: '',
    remember: false,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    const { email, password, remember } = data;
    const { userAgent } = navigator;
    try {
      await login(email, password, remember, userAgent);
    } catch (error) {
      console.error(error);

      const snackbarProps: OptionsObject = {
        variant: 'error',
      };

      enqueueSnackbar(translate(errorMessageHandler(error.error)), snackbarProps);
    }
  };

  const handleAnimationStart: AnimationEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    e
  ) => {
    const targetElement = e.target as HTMLElement;
    const autofilled = targetElement?.matches('*:-webkit-autofill');

    if (e.animationName === 'mui-auto-fill') {
      setHasSavedLoginData(autofilled);
    }

    if (e.animationName === 'mui-auto-fill-cancel') {
      setHasSavedLoginData(autofilled);
    }
  };

  const { email: emailValue, password } = watch();

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField
          name="email"
          size="small"
          label={translate('login.email')}
          disabled={!!email}
          autoComplete="email"
          InputLabelProps={{ shrink: hasSavedLoginData || !!emailValue }}
          inputProps={{
            onAnimationStart: handleAnimationStart,
            'data-testid': 'email',
          }}
        />

        <RHFTextField
          name="password"
          size="small"
          label={translate('login.password')}
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          InputLabelProps={{ shrink: hasSavedLoginData || !!password }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                  data-testid="show-password"
                >
                  <IconifyIcon icon={showPassword ? IconifyIconEnum.Eye : IconifyIconEnum.EyeOff} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{
            'data-testid': 'password',
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox
          name="remember"
          label={translate('login.staySignedIn')}
          data-testid="remember-me"
        />
        <Link
          component={RouterLink}
          variant="subtitle2"
          to={PATH_AUTH.resetPassword}
          data-testid="forgot-password"
        >
          {translate('login.forgotPassword')}
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        type="submit"
        variant="contained"
        loading={isSubmitting}
        data-testid="login"
      >
        <Typography variant="inherit">{translate('login.login')}</Typography>
      </LoadingButton>
    </FormProvider>
  );
}
