import { AdditionalAdminData } from 'src/@types/admin';

export const admins: AdditionalAdminData[] = [
  {
    firstName: 'Kriszti',
    lastName: 'Kalocsai',
    profileImg: '/assets/img/admin/Kriszti.jpg',
  },
  {
    firstName: 'Anna',
    lastName: 'Köcse',
    profileImg: '/assets/img/admin/Anna.jpg',
  },
  {
    firstName: 'Kitti',
    lastName: 'Ruder',
    profileImg: '/assets/img/admin/Kitti.jpg',
  },
  {
    firstName: 'Roland',
    lastName: 'Kalugyer',
    profileImg: '/assets/img/admin/Roland.jpg',
  },
];
