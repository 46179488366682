export const notFoundRecordConstants = {
  hu: {
    coupon: 'A kupon',
    event: 'A rendezvény',
    customizedStatistics: 'Az egyéni statisztika',
    guestInvitation: 'A vendég',
  },
  en: {
    coupon: 'The coupon',
    event: 'The event',
    customizedStatistics: 'The customized statistics',
    guestInvitation: 'The guest',
  },
};
