import { PagePermissionEnum, RoleEnum } from 'src/@types/auth';
import { Role } from 'src/@types/roles';

const {
  EVENT_CREATE,
  EVENT_LIST,
  EVENT_EDIT,
  EVENT_HISTORY,
  EVENT_LIMIT_MANAGEMENT,
  STATISTICS_SALES,
  STATISTICS_REPORT,
  STATISTICS_CUSTOMIZED,
  MARKETING_COUPON,
  SETTLEMENT,
  GUESTS_INVITE,
  GUESTS_LIST,
  GUESTS_LIMIT_MANAGEMENT,
  GUESTS_SETTINGS,
  GUESTS_ALL_USERS,
  USER_INVITE,
  USER_LIST,
  USER_EDIT,
  USER_HISTORY,
  NTAK_EDIT_LIST,
  NTAK_DATA_READ,
  NTAK_BOXOFFICE_EVENTS,
  NTAK_BOXOFFICE_SETTINGS,
} = PagePermissionEnum;

export const roles: Role[] = [
  {
    roleName: RoleEnum.FREE_ORGANIZER,
    permissions: [
      EVENT_CREATE,
      EVENT_LIST,
      EVENT_EDIT,
      EVENT_HISTORY,
      EVENT_LIMIT_MANAGEMENT,
      STATISTICS_SALES,
      STATISTICS_REPORT,
    ],
  },
  {
    roleName: RoleEnum.EVENT_ORGANIZER,
    permissions: [
      EVENT_CREATE,
      EVENT_LIST,
      EVENT_EDIT,
      EVENT_HISTORY,
      EVENT_LIMIT_MANAGEMENT,
      STATISTICS_SALES,
      STATISTICS_REPORT,
      STATISTICS_CUSTOMIZED,
      MARKETING_COUPON,
      SETTLEMENT,
      GUESTS_INVITE,
      GUESTS_LIST,
      GUESTS_LIMIT_MANAGEMENT,
      GUESTS_SETTINGS,
      GUESTS_ALL_USERS,
      USER_INVITE,
      USER_LIST,
      USER_EDIT,
      USER_HISTORY,
      NTAK_EDIT_LIST,
      NTAK_DATA_READ,
      NTAK_BOXOFFICE_EVENTS,
      NTAK_BOXOFFICE_SETTINGS,
    ],
  },
  {
    roleName: RoleEnum.MARKETING,
    permissions: [
      STATISTICS_SALES,
      STATISTICS_REPORT,
      STATISTICS_CUSTOMIZED,
      MARKETING_COUPON,
      USER_INVITE,
      USER_LIST,
      USER_EDIT,
      USER_HISTORY,
    ],
  },
  {
    roleName: RoleEnum.FINANCE,
    permissions: [
      STATISTICS_SALES,
      STATISTICS_CUSTOMIZED,
      SETTLEMENT,
      USER_INVITE,
      USER_LIST,
      USER_EDIT,
      USER_HISTORY,
    ],
  },
  // {
  //   roleName: RoleEnum.ENTRY_MANAGER,
  //   permissions: [
  //     ENTRY_LIST,
  //     ENTRY_EDIT,
  //     ENTRY_DELETE,
  //     USER_INVITE,
  //     USER_LIST,
  //     USER_EDIT,
  //     USER_HISTORY,
  //   ],
  // },
  {
    roleName: RoleEnum.CASHIER,
    permissions: [NTAK_DATA_READ, NTAK_BOXOFFICE_EVENTS, NTAK_BOXOFFICE_SETTINGS],
  },
  {
    roleName: RoleEnum.GUEST_TICKET_SENDER,
    permissions: [GUESTS_INVITE, GUESTS_LIST, GUESTS_SETTINGS],
  },
  {
    roleName: RoleEnum.TICKET_TRACKING,
    permissions: [STATISTICS_SALES],
  },
  // {
  //   roleName: RoleEnum.ENTRY,
  //   permissions: [ENTRY_LIST, USER_LIST],
  // },
];
