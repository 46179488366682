import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { UserOrganizersState } from 'src/@types/store';
import { userApi } from 'src/redux/api/userAPI';

let initialState: UserOrganizersState = { currentUserOrganizer: null };

const userOrganizersSlice = createSlice({
  name: 'organizer',
  initialState,
  reducers: {
    setCurrentUserOrganizer(state, action) {
      state.currentUserOrganizer = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<UserOrganizersState>) => {
    builder.addMatcher(userApi.endpoints.getOrganizers.matchFulfilled, (state, action) => {
      const savedOrganizerId = localStorage.getItem('currentOrganizerId');

      const [firstOrganizer] = action.payload;

      const currentOrganizer =
        savedOrganizerId && !!action.payload.find((organizer) => organizer.id === +savedOrganizerId)
          ? action.payload.find((organizer) => organizer.id === +savedOrganizerId)
          : firstOrganizer;

      currentOrganizer &&
        localStorage.setItem('currentOrganizerId', currentOrganizer.id.toString());

      state.currentUserOrganizer = currentOrganizer ? currentOrganizer : null;
    });
  },
});

export const userOrganizersActions = userOrganizersSlice.actions;

export default userOrganizersSlice.reducer;
