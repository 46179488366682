import useLocales from 'src/hooks/useLocales';

type Props = {
  name: string;
  englishName: string | null;
};

export default function MultilingualName({ name, englishName }: Props) {
  const { currentLang } = useLocales();

  return <>{currentLang.value === 'hu' ? name : englishName || name}</>;
}
