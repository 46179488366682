import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { Helmet } from 'react-helmet-async';
import { StyledChart } from './components/chart';
import { AutoLogoutProvider } from './components/AutoLogoutProvider';

export default function App() {
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src={`/js/new_relic_${process.env.REACT_APP_ENVIRONMENT}.js`}
        />
      </Helmet>
      <MotionLazyContainer>
        <ThemeProvider>
          <AutoLogoutProvider>
            <ProgressBarStyle />
            <ScrollToTop />
            <StyledChart />
            <Router />
          </AutoLogoutProvider>
        </ThemeProvider>
      </MotionLazyContainer>
    </>
  );
}
