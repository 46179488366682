import { useMemo, ReactNode, useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import useSettings from '../hooks/useSettings';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import useLocales from 'src/hooks/useLocales';
import useAuth from 'src/hooks/useAuth';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { useGetOrganizersQuery, useGetRolesQuery } from 'src/redux/api/userAPI';
import { OptionsObject, useSnackbar } from 'notistack';
import { userRolesActions } from 'src/redux/user-roles';
import { getHigherLevelRoles } from 'src/sections/@dashboard/user/utils/filter-accounts';
import { roles as defaultRoles } from 'src/assets/roles';

type Props = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const { translate } = useLocales();

  const { user } = useAuth();

  const currentOrganizer = useAppSelector((state) => state.userOrganizers.currentUserOrganizer);

  const { data: organizers } = useGetOrganizersQuery(user?.id, {
    skip: !user,
  });

  const { data: userRoles } = useGetRolesQuery(
    {
      userId: user?.id,
      organizerId: currentOrganizer?.id,
    },
    { skip: !user || !currentOrganizer }
  );

  const reduxDispatch = useAppDispatch();

  const currentRole = useAppSelector((state) => state.userRoles.currentUserRole);
  const previousRoles = useAppSelector((state) => state.userRoles.previousRoles);

  const { enqueueSnackbar } = useSnackbar();

  const { setCurrentUserRole, setPreviousRoles } = userRolesActions;

  useEffect(() => {
    const organizerIdForTheRoles = previousRoles[0]?.organizerId;

    if (
      !!userRoles?.length &&
      !!organizers?.length &&
      organizers?.find((organizer) => organizer.id === currentOrganizer?.id)
    ) {
      if (organizerIdForTheRoles === currentOrganizer?.id) {
        previousRoles?.forEach((previousRole) => {
          if (!userRoles.find((userRole) => userRole.id === previousRole.id)) {
            const higherLevelRoles = getHigherLevelRoles(previousRole.permissions, userRoles);
            const roleThatContainesAllEvents = higherLevelRoles?.some((higherLevelRole) =>
              previousRole.events.every((event) => higherLevelRole.events.includes(event))
            );

            if (!roleThatContainesAllEvents) {
              const snackbarProps: OptionsObject = {
                variant: 'warning',
                autoHideDuration: 10000,
              };

              const translatedRole = defaultRoles.find(
                (defaultRole) => defaultRole.roleName === previousRole.name
              )
                ? translate(`users.roles.${previousRole.name}`)
                : previousRole.name;

              enqueueSnackbar(
                translate('snackbar.deletedRole', {
                  role: translatedRole,
                }),
                snackbarProps
              );
            }
          }
        });
      }

      if (!userRoles?.find((userRole) => userRole.id === currentRole?.id)) {
        reduxDispatch(setCurrentUserRole(userRoles[0]));
      }

      reduxDispatch(setPreviousRoles(userRoles));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoles]);

  const { themeMode } = useSettings();

  const isLight = themeMode === 'light';

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight]
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
