import { SyntheticEvent, useEffect, useState } from 'react';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import axios from 'src/utils/axios';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { useGetOrganizersQuery } from 'src/redux/api/userAPI';
import { userOrganizersActions } from 'src/redux/user-organizers';
import { userRolesActions } from 'src/redux/user-roles';
import { OrganizerData } from 'src/@types/user';
import { socket } from 'src/services/socket/socket.io';

type Props = {
  isCurrentOrganizerDeleted?: boolean;
  onClose?: VoidFunction;
};

export default function OrganizerSelect({ isCurrentOrganizerDeleted, onClose }: Props) {
  const { translate } = useLocales();

  const { user } = useAuth();

  const dispatch = useAppDispatch();

  const { setCurrentUserOrganizer } = userOrganizersActions;
  const { setCurrentUserRole } = userRolesActions;

  const currentOrganizer = useAppSelector((state) => state.userOrganizers.currentUserOrganizer);

  const { data: organizers, isLoading, error } = useGetOrganizersQuery(user?.id);

  const [oldRolesRoom, setOldRolesRoom] = useState<string>();
  const [oldOrganizerRoom, setOldOrganizerRoom] = useState<string>();

  const selectedOrganizer = organizers?.find((organizer) => organizer.id === currentOrganizer?.id)
    ? currentOrganizer
    : null;

  useEffect(() => {
    handleJoinRooms(currentOrganizer?.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOrganizer = async (selectedOrganizer: OrganizerData | null) => {
    if (selectedOrganizer) {
      const { data: rolesByOrganizer } = await axios.get(`user/${user?.id}/role/list`, {
        params: { organizerId: selectedOrganizer.id },
      });

      const [role] = rolesByOrganizer;

      dispatch(setCurrentUserOrganizer(selectedOrganizer));
      dispatch(setCurrentUserRole(role));

      localStorage.setItem('currentOrganizerId', selectedOrganizer.id.toString());
      localStorage.setItem('currentAccountId', role.id.toString());

      handleJoinRooms(selectedOrganizer.id);

      onClose && onClose();
    }
  };

  const handleJoinRooms = (organizerId?: number) => {
    const rolesRoom = `user:${user?.id}-organizer:${organizerId}`;

    socket.emit('joinRoom', { newRoom: rolesRoom, oldRoom: oldRolesRoom });
    setOldRolesRoom(rolesRoom);

    const usersByOrganizerRoom = `organizer:${organizerId}`;

    socket.emit('joinRoom', { newRoom: usersByOrganizerRoom, oldRoom: oldOrganizerRoom });
    setOldOrganizerRoom(usersByOrganizerRoom);
  };

  return (
    <>
      {!!organizers?.length && !isLoading && !error && (
        <Autocomplete
          fullWidth
          size="small"
          autoHighlight
          disabled={organizers.length === 1 && !isCurrentOrganizerDeleted}
          options={organizers}
          value={selectedOrganizer}
          getOptionLabel={(option) =>
            `${option.name} ${option.companyName ? `(${option.companyName})` : ''}`
          }
          data-testid="organizer-select"
          onChange={(_e: SyntheticEvent<Element, Event>, value: OrganizerData | null) =>
            handleOrganizer(value)
          }
          renderOption={(props, option) => (
            <MenuItem {...props} key={option.id}>
              {`${option.name} ${option.companyName ? `(${option.companyName})` : ''}`}
            </MenuItem>
          )}
          renderInput={(params) => (
            <TextField {...params} label={translate('organizerSelectLabel')} />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      )}
    </>
  );
}
