import { ReactNode } from 'react';
// form
import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  methods: UseFormReturn<any>;
  testId?: string;
  onSubmit?: VoidFunction;
};

export default function FormProvider({ children, onSubmit, methods, testId }: Props) {
  return (
    <Form {...methods}>
      <form
        data-testid={testId}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit && onSubmit();
        }}
      >
        {children}
      </form>
    </Form>
  );
}
