export type LocationData = {
  id: number;
  name: string;
  addressCountry: string;
  postalCode: string;
  addressLocality: string;
  streetAddress: string;
  map: string;
};

export enum Language {
  HU = 'hu',
  EN = 'en',
}