import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  isCollapseSideBar?: boolean;
  disabledLink?: boolean;
}

export function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 30 33">
        <g
          style={{ width: '100%' }}
          fill={PRIMARY_MAIN}
          fillRule="evenodd"
          stroke="none"
          strokeWidth="1"
        >
          <path d="M28.354,21.366c0.306,4.69 -5.098,5.2 -6.831,2.855c-2.169,-2.934 -5.05,-2.797 -6.619,-1.438c-1.569,-1.359 -4.449,-1.496 -6.618,1.438c-1.733,2.345 -7.137,1.835 -6.831,-2.855c0,0 -2.763,4.258 0.611,8.055c3.263,3.671 8.689,3.784 12.032,0.918c0.305,-0.262 0.568,-0.53 0.806,-0.802c0.238,0.272 0.501,0.54 0.807,0.802c3.343,2.866 8.768,2.753 12.031,-0.918c3.375,-3.796 0.612,-8.055 0.612,-8.055Z" />
          <path d="M28.354,11.37c0.306,-4.69 -5.098,-5.2 -6.831,-2.855c-2.169,2.934 -5.05,2.797 -6.619,1.438c-1.569,1.359 -4.449,1.496 -6.618,-1.438c-1.733,-2.345 -7.137,-1.835 -6.831,2.855c0,0 -2.763,-4.258 0.611,-8.055c3.263,-3.671 8.689,-3.784 12.032,-0.918c0.305,0.262 0.568,0.53 0.806,0.802c0.238,-0.272 0.501,-0.54 0.807,-0.802c3.343,-2.866 8.768,-2.753 12.031,0.918c3.375,3.796 0.612,8.055 0.612,8.055Z" />
          <circle cx="12.241" cy="16.368" r="1.332" />
          <circle cx="16.902" cy="16.368" r="1.332" />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}

export function LogoBlack({ disabledLink = false, sx }: Props) {
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 33 33">
        <g style={{ width: '100%' }} fill="black" fillRule="evenodd" stroke="none" strokeWidth="1">
          <path d="M28.354,21.366c0.306,4.69 -5.098,5.2 -6.831,2.855c-2.169,-2.934 -5.05,-2.797 -6.619,-1.438c-1.569,-1.359 -4.449,-1.496 -6.618,1.438c-1.733,2.345 -7.137,1.835 -6.831,-2.855c0,0 -2.763,4.258 0.611,8.055c3.263,3.671 8.689,3.784 12.032,0.918c0.305,-0.262 0.568,-0.53 0.806,-0.802c0.238,0.272 0.501,0.54 0.807,0.802c3.343,2.866 8.768,2.753 12.031,-0.918c3.375,-3.796 0.612,-8.055 0.612,-8.055Z" />
          <path d="M28.354,11.37c0.306,-4.69 -5.098,-5.2 -6.831,-2.855c-2.169,2.934 -5.05,2.797 -6.619,1.438c-1.569,1.359 -4.449,1.496 -6.618,-1.438c-1.733,-2.345 -7.137,-1.835 -6.831,2.855c0,0 -2.763,-4.258 0.611,-8.055c3.263,-3.671 8.689,-3.784 12.032,-0.918c0.305,0.262 0.568,0.53 0.806,0.802c0.238,-0.272 0.501,-0.54 0.807,-0.802c3.343,-2.866 8.768,-2.753 12.031,0.918c3.375,3.796 0.612,8.055 0.612,8.055Z" />
          <circle cx="12.241" cy="16.368" r="1.332" />
          <circle cx="16.902" cy="16.368" r="1.332" />
        </g>
      </svg>
    </Box>
  );

  return <>{logo}</>;
}

export function LogoWithText({ disabledLink = false, sx }: Props) {
  const theme = useTheme();

  const PROD_COLOR = theme.palette.primary.main;
  const STAGING_COLOR = 'red';
  const DEV_COLOR = theme.palette.action.active;

  let logoColor = '';

  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'production':
      logoColor = PROD_COLOR;
      break;
    case 'release':
      logoColor = STAGING_COLOR;
      break;
    default:
      logoColor = DEV_COLOR;
  }

  const logoWithText = (
    <Box sx={{ width: 140, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="40 0 33 33">
        <g
          style={{ width: '100%' }}
          fill={logoColor}
          fillRule="evenodd"
          stroke="none"
          strokeWidth="1"
        >
          <path d="M28.354,11.37c0.306,-4.69 -5.098,-5.2 -6.831,-2.855c-2.169,2.934 -5.05,2.797 -6.619,1.438c-1.569,1.359 -4.449,1.496 -6.618,-1.438c-1.733,-2.345 -7.137,-1.835 -6.831,2.855c0,0 -2.763,-4.258 0.611,-8.055c3.263,-3.671 8.689,-3.784 12.032,-0.918c0.305,0.262 0.568,0.53 0.806,0.802c0.238,-0.272 0.501,-0.54 0.807,-0.802c3.343,-2.866 8.768,-2.753 12.031,0.918c3.375,3.796 0.612,8.055 0.612,8.055Z" />
          <circle cx="12.241" cy="16.368" r="1.332" />
          <circle cx="16.902" cy="16.368" r="1.332" />
          <path d="M28.354,21.366c0.306,4.69 -5.098,5.2 -6.831,2.855c-2.169,-2.934 -5.05,-2.797 -6.619,-1.438c-1.569,-1.359 -4.449,-1.496 -6.618,1.438c-1.733,2.345 -7.137,1.835 -6.831,-2.855c0,0 -2.763,4.258 0.611,8.055c3.263,3.671 8.689,3.784 12.032,0.918c0.305,-0.262 0.568,-0.53 0.806,-0.802c0.238,0.272 0.501,0.54 0.807,0.802c3.343,2.866 8.768,2.753 12.031,-0.918c3.375,-3.796 0.612,-8.055 0.612,-8.055Z" />
          <path d="M59.654,6.335l0,5.691l-5.73,0l0,13.193l-7.515,0l0,-13.147l-5.731,0l0,-5.737l18.976,0Z" />
          <rect x="61.187" y="6.342" width="6.698" height="18.911" />
          <path d="M89.146,6.271c-2.087,2.974 -4.038,5.753 -6.039,8.604c1.968,3.411 3.942,6.833 6.02,10.434c-2.908,0 -5.596,0.014 -8.284,-0.025c-0.228,-0.003 -0.526,-0.317 -0.663,-0.559c-0.559,-0.986 -1.068,-2.001 -1.698,-3.202c-0.649,1.062 -1.269,1.982 -1.786,2.957c-0.348,0.658 -0.797,0.87 -1.523,0.853c-1.934,-0.045 -3.869,-0.015 -6.019,-0.015c1.733,-2.622 3.245,-5.103 4.963,-7.431c0.923,-1.251 0.94,-2.138 0.063,-3.432c-1.751,-2.579 -3.285,-5.306 -4.969,-8.072c0.297,-0.045 0.516,-0.107 0.735,-0.108c2.344,-0.007 4.689,0.018 7.032,-0.019c0.622,-0.009 0.972,0.195 1.247,0.741c0.475,0.94 1.019,1.846 1.607,2.898c0.707,-1.095 1.311,-2.088 1.982,-3.033c0.192,-0.269 0.577,-0.558 0.879,-0.565c2.058,-0.05 4.117,-0.026 6.453,-0.026" />
          <path d="M99.636,13.719c-0.569,1.804 -1.048,3.323 -1.542,4.889l3.215,0c-0.549,-1.606 -1.062,-3.104 -1.673,-4.889m11.754,11.595c-2.575,0 -4.95,0.027 -7.323,-0.036c-0.267,-0.007 -0.637,-0.476 -0.76,-0.805c-0.592,-1.582 -0.567,-1.591 -2.263,-1.592c-1.452,0 -2.903,0 -4.316,0c-0.913,2.437 -0.913,2.437 -3.473,2.437l-3.674,0c2.395,-6.454 4.723,-12.727 7.07,-19.049c2.38,0 4.725,-0.037 7.065,0.044c0.312,0.01 0.736,0.565 0.884,0.953c2.099,5.504 4.159,11.022 6.228,16.537c0.16,0.426 0.318,0.854 0.562,1.511" />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logoWithText}</>;
  }

  return <RouterLink to="/">{logoWithText}</RouterLink>;
}
