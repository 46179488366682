import React, { Fragment, ReactNode, useMemo, useState } from 'react';
import { useAppSelector } from 'src/redux/hook';
import useAuth from 'src/hooks/useAuth';
import { EventData } from 'src/@types/event';
import { PagePermissionEnum } from 'src/@types/auth';
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
 IconButton } from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'src/components/table';
import useLocales from 'src/hooks/useLocales';
import useTable, { OrderEnum } from 'src/hooks/useTable';
import { useGetRolesQuery } from 'src/redux/api/userAPI';
import { TableEnum } from 'src/components/table/type';
import OfflineTicketTableToolbar from './OfflineTicketTableToolbar';
import { OfflineTicketType } from 'src/@types/OfflineTickets';
import useResponsive from 'src/hooks/useResponsive';
import AmountWithCurrency from 'src/components/AmountWithCurrency';
import IconifyIcon from 'src/components/icons/IconifyIcon';
import { IconifyIconEnum } from 'src/@types/icon';
import DeleteDialog from 'src/sections/@dashboard/event/new-event/DeleteDialog';

interface OfflineTicketEditorTableProps {
  event: EventData;
  offlineTickets: OfflineTicketType[];
  handleEditRow: (
    event: React.MouseEvent<HTMLButtonElement>,
    offlineTicket: OfflineTicketType,
  ) => void;
  deleteOfflineTicket: (offlineTicket: OfflineTicketType) => void;
}

const OfflineTicketEditorTable: React.FC<OfflineTicketEditorTableProps> = ({
  event,
  offlineTickets,
  handleEditRow,
  deleteOfflineTicket,
}) => {
  const { translate, currentLang } = useLocales();
  const isMobile = useResponsive('down', 'sm');

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultDense: 'true',
    defaultOrderBy: 'name',
    defaultOrder: 'asc' as OrderEnum,
  });

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteDialogContent, setDeleteDialogContent] = useState<string | ReactNode>('');

  const { user } = useAuth();
  const currentOrganizer = useAppSelector((state) => state.userOrganizers.currentUserOrganizer);

  const { data: userRoles } = useGetRolesQuery(
    {
      userId: user?.id,
      organizerId: currentOrganizer?.id,
    },
    { skip: !user || !currentOrganizer }
  );

  const isNotFound = offlineTickets.length === 0;

  const hasPermissionToThisPageRoles = useMemo(
    () =>
      userRoles?.filter((role) =>
        role.permissions.includes(PagePermissionEnum.NTAK_BOXOFFICE_EVENTS)
      ),
    [userRoles]
  );

  const showOfflineTicketTableToolbar =
    hasPermissionToThisPageRoles && hasPermissionToThisPageRoles.length > 1;

  const TABLE_HEAD = useMemo(
    () => [
      {
        id: 'ticketTypeName',
        label: translate('ntak.boxoffice.offlineTicket.name'),
        width: 50,
        align: 'left',
      },
      {
        id: 'ticketPrice',
        label: translate('ntak.boxoffice.offlineTicket.price'),
        width: 20,
        align: 'left',
      },
      {
        id: 'limit',
        label: translate('ntak.boxoffice.offlineTicket.quantity'),
        width: 2,
        align: 'left',
      },
      { id: 'menu', label: '', width: '30px', align: 'right' },
    ],
    [translate]
  );

  const handleDeleteRow = (offlineTicket: OfflineTicketType) => {
    deleteOfflineTicket(offlineTicket);
    setOpenDeleteDialog(false);
  };

  const handleOpenDeleteDialog = (
    offlineTicket: OfflineTicketType,
  ) => {
    setDeleteDialogContent(
      translate('ntak.boxoffice.offlineTicket.actions.ticketDeletionAllowed', {
        ticketName: offlineTicket.ticketTypeName,
      })
    );
    setOpenDeleteDialog(true);
  };

  return (
    <>
      {showOfflineTicketTableToolbar && (
        <OfflineTicketTableToolbar hasPermissionToThisPageRoles={hasPermissionToThisPageRoles} />
      )}
      <Scrollbar>
        <TableContainer
          sx={{
            minWidth: 500,
            position: 'relative',
            overflow: 'unset',
            marginTop: showOfflineTicketTableToolbar ? 0 : 4,
          }}
        >
          <Table size='small'>
            <TableHeadCustom table={TableEnum.OFFLINE_TICKETS} headLabel={TABLE_HEAD} />
            <TableBody>
              {offlineTickets
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((offlineTicket, index) => (
                  <Fragment key={offlineTicket.id}>
                    <TableRow>
                      <TableCell>{offlineTicket.ticketTypeName}</TableCell>
                      <TableCell>
                        <AmountWithCurrency amount={offlineTicket.ticketPrice} />
                      </TableCell>
                      <TableCell>{offlineTicket.limit}</TableCell>
                      <TableCell align='right'>
                        <Stack flexDirection="row" justifyContent="flex-end">
                          <Tooltip
                            enterTouchDelay={0}
                            leaveTouchDelay={15000}
                            title={translate('eventList.table.actions.edit')}
                            placement="top"
                          >
                            <IconButton
                              onClick={(event) =>
                                handleEditRow(event, offlineTicket)
                              }
                              sx={{ color: 'primary.main' }}
                            >
                              <IconifyIcon icon={IconifyIconEnum.Edit} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            enterTouchDelay={0}
                            leaveTouchDelay={15000}
                            title={translate('eventList.table.actions.delete')}
                            placement="top"
                          >
                            <IconButton
                              onClick={() =>
                                handleOpenDeleteDialog(offlineTicket)
                              }
                              sx={{ color: 'error.main' }}
                            >
                              <IconifyIcon icon={IconifyIconEnum.Delete} />
                            </IconButton>
                          </Tooltip>
                          <DeleteDialog
                            open={openDeleteDialog}
                            dataTestId={'deleteDialog'}
                            onClose={() => setOpenDeleteDialog(false)}
                            deleteDialogContent={deleteDialogContent}
                            isDeleteAllowed={true}
                            handleDelete={() => handleDeleteRow(offlineTicket)}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              {<TableNoData isNotFound={isNotFound} dense={isMobile} />}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={offlineTickets.length ?? 0}
          labelRowsPerPage={translate('eventList.table.footer.rowsPerPage')}
          labelDisplayedRows={(page) => {
            const { from, to, count } = page;

            return currentLang.value === 'hu'
              ? `${from}–${to} / ${count !== -1 ? count : `more than ${to}`}`
              : `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
          }}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default OfflineTicketEditorTable;
