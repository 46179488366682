import { forwardRef } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText, OutlinedInput, Select, SelectProps } from '@mui/material';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  children: React.ReactNode;
};

type Props = IProps & SelectProps<number[]>;

const RHFMultipleSelect = forwardRef((props: Props, ref) => {
  const { translate } = useLocales();
  const { control } = useFormContext();

  const { name, children, ...other } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref: fieldRef, ...fieldProps }, fieldState: { error } }) => (
        <>
          <Select
            {...fieldProps}
            multiple
            fullWidth
            ref={ref}
            inputRef={fieldRef}
            input={<OutlinedInput label={translate('userInvite.form.event')} />}
            error={!!error && !fieldProps.value.length}
            {...other}
          >
            {children}
          </Select>

          {!!error && !fieldProps.value.length && (
            <FormHelperText error>{error.message}</FormHelperText>
          )}
        </>
      )}
    />
  );
});

export default RHFMultipleSelect;
