import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { RoleData } from 'src/@types/user';

import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';

import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { userRolesActions } from 'src/redux/user-roles';
import { useGetRolesQuery } from 'src/redux/api/userAPI';

import { roles as rolesData } from 'src/assets/roles';

type Props = {
  roles: RoleData[];
  currentRole?: RoleData | null;
  size?: 'medium' | 'small' | undefined;
};

export default function RoleSelect({ currentRole, roles, size = 'medium' }: Props) {
  const { translate, currentLang } = useLocales();

  const dispatch = useAppDispatch();

  const { setCurrentUserRole } = userRolesActions;

  const { user } = useAuth();
  const currentOrganizer = useAppSelector((state) => state.userOrganizers.currentUserOrganizer);

  const { data: userRoles } = useGetRolesQuery(
    {
      userId: user?.id,
      organizerId: currentOrganizer?.id,
    },
    { skip: !user || !currentOrganizer }
  );

  const handleRole = (e: SelectChangeEvent<number>) => {
    const {
      target: { value },
    } = e;

    const role = userRoles?.find((userRole) => userRole.id === value);

    if (role) {
      dispatch(setCurrentUserRole(role));

      localStorage.setItem('currentAccountId', value.toString());
    }
  };

  return (
    <FormControl size={size} fullWidth>
      <InputLabel id="role-select">{translate('userInvite.form.selectOwnRole.myRoles')}</InputLabel>
      <Select
        onChange={handleRole}
        name="role-select"
        labelId="role-select"
        label={translate('userInvite.form.selectOwnRole.myRoles')}
        size="small"
        defaultValue={0}
        value={currentRole?.id}
      >
        <MenuItem value={0} disabled>
          {translate('userInvite.form.selectOwnRole.myRolesPlaceholder')}
        </MenuItem>
        {roles.map((role) => (
          <MenuItem key={role.id} value={role.id}>
            {currentRole?.userData?.firstName && currentRole?.userData?.lastName
              ? currentLang.value === 'hu'
                ? `${currentRole?.userData.lastName} ${currentRole?.userData.firstName} - ${
                    rolesData.find((roleData) => roleData.roleName === role.name)
                      ? translate(`users.roles.${role.name}`)
                      : role.name
                  }`
                : `${currentRole?.userData.firstName} ${currentRole?.userData.lastName} - ${
                    rolesData.find((roleData) => roleData.roleName === role.name)
                      ? translate(`users.roles.${role.name}`)
                      : role.name
                  }`
              : rolesData.find((roleData) => roleData.roleName === role.name)
              ? translate(`users.roles.${role.name}`)
              : role.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
