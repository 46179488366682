import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Stack, Box } from '@mui/material';
import { PATH_AUTH } from '../../routes/paths';
import useResponsive from '../../hooks/useResponsive';
import Page from '../../components/Page';
import { LogoWithText } from '../../components/Logo';
import Image from '../../components/Image';
import { LoginForm } from '../../sections/auth/login';
import useLocales from 'src/hooks/useLocales';
import LanguagePopover from 'src/layouts/dashboard/header/LanguagePopover';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

type Props = {
  email?: string;
};

export default function Login({ email }: Props) {
  const { translate } = useLocales();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title={translate('pageTitles.login')}>
      <RootStyle>
        <HeaderStyle>
          <LogoWithText />
          <Box display="flex" gap={1}>
            <Box sx={{ mt: { md: -2.95 } }}>
              <LanguagePopover />
            </Box>
            {smUp && (
              <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                {translate('login.dontHaveAccount')}
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  {translate('login.getStarted')}
                </Link>
              </Typography>
            )}
          </Box>
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 2 }}>
              {translate('login.welcomeMessage')}
            </Typography>

            <Image
              visibleByDefault
              disabledEffect
              src="/assets/illustrations/illustration_login.svg"
              alt="login"
              sx={{ maxWidth: 320, alignSelf: 'center' }}
            />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {translate('login.header')}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  {translate('login.details')}
                </Typography>
              </Box>
            </Stack>

            <LoginForm email={email} />

            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                {translate('login.dontHaveAccount')}
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  {translate('login.getStarted')}
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
