import { LocationsState } from '../@types/store';
import { createSlice } from '@reduxjs/toolkit';

const initialState: LocationsState = { locations: [] };

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocations(state: LocationsState, action) {
      state.locations = action.payload;
    },
    updateLocations(state: LocationsState, action) {
      state.locations = [...state.locations, action.payload];
    },
  },
});

export const locationsActions = locationsSlice.actions;

export default locationsSlice.reducer;
