import { RoleData } from 'src/@types/user';
import { getNavConfig } from '../navbar/NavConfig';
import { NavConfig, NavItem } from 'src/@types/menu';
import { FeatureFlags } from 'src/@types/types';

export const getFilteredNavConfig = (params: {
  currentRole: RoleData | null;
  featureFlags?: FeatureFlags;
  permissions: number[];
}) => {
  const { currentRole, featureFlags, permissions } = params;

  const filteredNavConfig = getNavConfig(featureFlags).reduce(
    (filteredNavConfig: NavConfig[], navConfig: NavConfig) => {
      const navItems = navConfig.items.reduce((navItems: NavItem[], navItem: NavItem) => {
        if (navItem.disabledFeature) {
          return navItems;
        }

        if (currentRole?.isOrganizer) {
          navItems.push(navItem);

          return navItems;
        }

        if (!navItem.children && (!navItem.value || permissions.includes(navItem.value))) {
          navItems.push(navItem);
        }

        if (navItem.children) {
          const filteredChildren = navItem.children.filter(
            (child) => !child.value || permissions.includes(child.value)
          );

          !!filteredChildren.length && navItems.push({ ...navItem, children: filteredChildren });
        }

        return navItems;
      }, []);

      !!navItems.length && filteredNavConfig.push({ ...navConfig, items: navItems });

      return filteredNavConfig;
    },
    []
  );

  return filteredNavConfig;
};
