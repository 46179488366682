// form
import { useFormContext, Controller } from 'react-hook-form';
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  size?: 'small' | 'medium';
};

type Props = IProps & MuiTelInputProps;

export default function RHFTextField({ name, label, size, langOfCountryName }: Props) {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => (
        <MuiTelInput
          {...fieldProps}
          forceCallingCode
          size={size}
          langOfCountryName={langOfCountryName}
          fullWidth
          value={fieldProps.value}
          inputRef={ref}
          error={!!error}
          helperText={error?.message}
          variant="outlined"
          placeholder="+36 30 123 4567"
          defaultCountry="HU"
          label={label}
          onChange={(value, info) =>
            value === `+${info.countryCallingCode}` || !info.countryCallingCode
              ? setValue('phoneNumber', '', { shouldValidate: true })
              : setValue('phoneNumber', value, {
                  shouldValidate: errors.phoneNumber ? true : false,
                })
          }
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          inputProps={{
            'data-testid': 'phone-number',
          }}
        />
      )}
    />
  );
}
