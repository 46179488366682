import { Suspense, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LoadingScreen from '../components/LoadingScreen';
import GuestGuard from 'src/guards/GuestGuard';
import AuthGuard from 'src/guards/AuthGuard';
import EventGuard from 'src/guards/EventGuard';
import { lazyWithRetry } from 'src/utils/lazy-with-retry';
import NtakBoxOfficeOfflineTicketEditor from 'src/pages/dashboard/NtakBoxOfficeOfflineTicketEditor';
import { useGetFeatureFlagsQuery } from 'src/redux/api/userAPI';
import useAuth from 'src/hooks/useAuth';
import { useAppSelector } from 'src/redux/hook';
import { PagePermissionEnum } from 'src/@types/auth';
import { getFilteredRoutes } from './utils/filtered-routes';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    // TODO check suspense component - react
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { isAuthenticated } = useAuth();

  const currentOrganizer = useAppSelector((state) => state.userOrganizers.currentUserOrganizer);
  const currentRole = useAppSelector((state) => state.userRoles.currentUserRole);
  const permissionsByRoles = useAppSelector((state) => state.userRoles.permissionsByRoles);

  const { data: featureFlags, isLoading: isLoadingFeatureFlags } = useGetFeatureFlagsQuery(
    {
      organizerId: currentOrganizer?.id,
    },
    { skip: !currentOrganizer }
  );

  let routes = [
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: <Register />,
        },
        {
          path: 'reset-password',
          children: [
            { path: '', element: <ResetPassword /> },
            { path: 'email-sent', element: <EmailSent /> },
          ],
        },
        {
          path: 'resend-email-confirmation',
          children: [
            { path: '', element: <ResendEmailConfirmation /> },
            { path: 'email-sent', element: <EmailSent /> },
          ],
        },
        {
          path: 'new-password',
          element: (
            <GuestGuard>
              <NewPassword />
            </GuestGuard>
          ),
        },
        { path: 'confirm-email', element: <ConfirmEmail /> },
        { path: 'confirm-invitation', element: <ConfirmInvitation /> },
        { path: 'confirmed-email', element: <ConfirmedEmail /> },
      ],
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/home" replace />, index: true },
        { path: 'home', element: <Home /> },
        {
          path: 'event',
          children: [
            {
              element: <Navigate to="/dashboard/event/list" replace />,
              index: true,
              permission: PagePermissionEnum.EVENT_LIST,
            },
            { path: 'add', element: <EventCreate />, permission: PagePermissionEnum.EVENT_CREATE },
            { path: 'list', element: <EventList />, permission: PagePermissionEnum.EVENT_LIST },
            {
              path: ':id/edit',
              element: (
                <EventGuard>
                  <EventEdit />
                </EventGuard>
              ),
              permission: PagePermissionEnum.EVENT_EDIT,
            },
            {
              path: 'limit-management',
              element: <EventLimitManagement />,
              permission: PagePermissionEnum.EVENT_LIMIT_MANAGEMENT,
            },
            {
              path: 'history',
              element: <EventHistory />,
              permission: PagePermissionEnum.EVENT_HISTORY,
            },
          ],
        },
        {
          path: 'statistics',
          children: [
            {
              element: <Navigate to="/dashboard/statistics/sales" replace />,
              index: true,
              permission: PagePermissionEnum.STATISTICS_SALES,
            },
            {
              path: 'report',
              element: <StatisticsReport />,
              permission: PagePermissionEnum.STATISTICS_REPORT,
            },
            {
              path: 'sales',
              element: <StatisticsSales />,
              permission: PagePermissionEnum.STATISTICS_SALES,
            },
            {
              path: 'sales/event/:id/details',
              element: <StatisticsSalesDetails />,
              permission: PagePermissionEnum.STATISTICS_SALES,
            },
            {
              path: 'customized-statistics',
              element: <StatisticsCustomized />,
              permission: PagePermissionEnum.STATISTICS_CUSTOMIZED,
            },
            {
              path: 'customized-statistics/:id/category',
              element: <StatisticsCustomizedCategory />,
              permission: PagePermissionEnum.STATISTICS_CUSTOMIZED,
            },
          ],
        },
        {
          path: 'marketing',
          disabledFeature: !featureFlags?.coupons.enabled,
          children: [
            {
              element: <Navigate to="/dashboard/marketing/coupon" replace />,
              index: true,
              permission: PagePermissionEnum.MARKETING_COUPON,
            },
            {
              path: 'coupon',
              element: <MarketingCoupon />,
              permission: PagePermissionEnum.MARKETING_COUPON,
            },
            {
              path: 'coupon/add',
              element: <MarketingCouponAdd />,
              permission: PagePermissionEnum.MARKETING_COUPON,
            },
            {
              path: 'coupon/:id/edit',
              element: <MarketingCouponAdd />,
            },
            {
              path: 'coupon/:id/coupon-code',
              element: <MarketingCouponCode />,
              permission: PagePermissionEnum.MARKETING_COUPON,
            },
            {
              path: 'coupon/:id/statistics',
              element: <MarketingCouponStatistics />,
              permission: PagePermissionEnum.MARKETING_COUPON,
            },
          ],
        },
        {
          path: 'finance',
          children: [
            {
              element: <Navigate to="/dashboard/finance/settlement" replace />,
              index: true,
              permission: PagePermissionEnum.SETTLEMENT,
            },
            {
              path: 'settlement',
              element: <FinanceSettlement />,
              permission: PagePermissionEnum.SETTLEMENT,
            },
            {
              path: 'settlement/event/:id/detailed-settlement',
              element: <DetailedSettlementPage />,
              permission: PagePermissionEnum.SETTLEMENT,
            },
          ],
        },
        {
          path: 'event',
          disabledFeature: !featureFlags?.guest_invitation.enabled,
          children: [
            {
              element: <Navigate to="/dashboard/event/guest/list" replace />,
              index: true,
              permission: PagePermissionEnum.GUESTS_LIST,
            },
            {
              path: 'guest/invite',
              element: <GuestInvite />,
              permission: PagePermissionEnum.GUESTS_INVITE,
            },
            {
              path: ':id/guest/invite/statistics',
              element: <GuestInviteStatisticsPage />,
              permission: PagePermissionEnum.GUESTS_INVITE,
            },
            {
              path: 'guest/list',
              element: <GuestList />,
              permission: PagePermissionEnum.GUESTS_LIST,
            },
            {
              path: ':eventId/guest/:guestId',
              element: <GuestDetails />,
              permission: PagePermissionEnum.GUESTS_LIST,
            },
            {
              path: 'guest/settings',
              element: <GuestSettings />,
              permission: PagePermissionEnum.GUESTS_SETTINGS,
            },
            {
              path: 'guest/limit-management/users',
              element: <GuestLimit />,
              permission: PagePermissionEnum.GUESTS_LIMIT_MANAGEMENT,
            },
            {
              path: 'guest/limit-management/users/:id/events',
              element: <GuestLimitEvents />,
              permission: PagePermissionEnum.GUESTS_LIMIT_MANAGEMENT,
            },
          ],
        },
        {
          path: 'box-office',
          children: [
            {
              element: <Navigate to="/dashboard/box-office/events" replace />,
              index: true,
              permission: PagePermissionEnum.NTAK_BOXOFFICE_EVENTS,
            },
            {
              path: 'events',
              element: <NtakBoxOfficeEvents />,
              permission: PagePermissionEnum.NTAK_BOXOFFICE_EVENTS,
            },
            {
              path: 'settings',
              element: <NtakBoxOfficeSettings />,
              permission: PagePermissionEnum.NTAK_BOXOFFICE_SETTINGS,
            },
            {
              path: 'event/:id/offline-ticket-types',
              element: <NtakBoxOfficeOfflineTicketEditor />,
              permission: PagePermissionEnum.NTAK_BOXOFFICE_EVENTS,
            },
          ],
        },
        {
          path: 'ntak',
          children: [
            {
              element: <Navigate to="/dashboard/ntak/attraction/edit" replace />,
              index: true,
              permission: PagePermissionEnum.NTAK_EDIT_LIST,
            },
            {
              path: 'attraction/register',
              element: <NtakAttractionRegistration />,
              permission: PagePermissionEnum.NTAK_EDIT_LIST,
            },
            {
              path: 'attraction/list',
              element: <NtakAttractionList />,
              permission: PagePermissionEnum.NTAK_EDIT_LIST,
            },
          ],
        },
        {
          path: 'account',
          children: [
            { element: <Navigate to="/dashboard/account/profile" replace />, index: true },
            { path: 'profile', element: <AccountProfile /> },
            { path: 'company-data', element: <AccountCompanyData /> },
          ],
        },
        {
          path: 'invited-user',
          children: [
            {
              element: <Navigate to="/dashboard/invited-user/list" replace />,
              index: true,
              permission: PagePermissionEnum.USER_LIST,
            },
            { path: 'invite', element: <UserInvite />, permission: PagePermissionEnum.USER_INVITE },
            { path: 'list', element: <UserList />, permission: PagePermissionEnum.USER_LIST },
          ],
        },
        { path: 'history', element: <UserHistory />, permission: PagePermissionEnum.USER_HISTORY },
        { path: 'offer', element: <Offer /> },
        { path: 'recommendation', element: <Recommendation /> },
      ],
    },
    {
      path: 'event-report',
      element: <LogoOnlyLayout disabledLink={true} />,
      children: [{ path: 'unsubscribe', element: <StatisticsReportSubscription /> }],
    },
    {
      path: 'guest/settings',
      element: <LogoOnlyLayout disabledLink />,
      children: [{ path: 'reply-to-confirmation', element: <GuestReplyToConfirmation /> }],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Navigate to="/dashboard/home" replace />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];

  if (isAuthenticated && !isLoadingFeatureFlags && currentRole) {
    routes = getFilteredRoutes({
      routes,
      permissions: permissionsByRoles,
      isOrganizer: currentRole?.isOrganizer,
    });
  }

  return useRoutes(routes);
}

// AUTHENTICATION
const Login = Loadable(lazyWithRetry(() => import('../pages/auth/Login')));
const Register = Loadable(lazyWithRetry(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazyWithRetry(() => import('../pages/auth/ResetPassword')));
const ResendEmailConfirmation = Loadable(
  lazyWithRetry(() => import('../pages/auth/ResendEmailConfirmation'))
);
const NewPassword = Loadable(lazyWithRetry(() => import('../pages/auth/NewPassword')));
const EmailSent = Loadable(lazyWithRetry(() => import('../pages/auth/EmailSent')));
const ConfirmEmail = Loadable(lazyWithRetry(() => import('../pages/auth/ConfirmEmail')));
const ConfirmInvitation = Loadable(
  lazyWithRetry(() => import('../sections/@dashboard/user/ConfirmInvitation'))
);
const ConfirmedEmail = Loadable(lazyWithRetry(() => import('../pages/auth/ConfirmedEmail')));

// DASHBOARD
const Home = Loadable(lazyWithRetry(() => import('../pages/dashboard/Home')));

// ACCOUNT
const AccountProfile = Loadable(lazyWithRetry(() => import('../pages/dashboard/AccountProfile')));
const AccountCompanyData = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/AccountCompanyData'))
);

// EVENT
const EventList = Loadable(lazyWithRetry(() => import('../pages/dashboard/EventList')));
const EventCreate = Loadable(lazyWithRetry(() => import('../pages/dashboard/EventCreate')));
const EventEdit = Loadable(lazyWithRetry(() => import('../pages/dashboard/EventEdit')));
const EventLimitManagement = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/EventLimitManagement'))
);
const EventHistory = Loadable(lazyWithRetry(() => import('../pages/dashboard/EventHistory')));

// MARKETING
const MarketingCoupon = Loadable(lazyWithRetry(() => import('../pages/dashboard/MarketingCoupon')));
const MarketingCouponAdd = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/MarketingCouponAdd'))
);
const MarketingCouponCode = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/MarketingCouponCode'))
);
const MarketingCouponStatistics = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/MarketingCouponStatistics'))
);

// FINANCE
const FinanceSettlement = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/FinanceSettlement'))
);
const DetailedSettlementPage = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/DetailedSettlementPage'))
);

// GUESTS
const GuestInvite = Loadable(lazyWithRetry(() => import('../pages/dashboard/GuestInvite')));
const GuestInviteStatisticsPage = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/GuestInviteStatisticsPage'))
);
const GuestList = Loadable(lazyWithRetry(() => import('../pages/dashboard/Guestlist')));
const GuestDetails = Loadable(lazyWithRetry(() => import('../pages/dashboard/GuestDetails')));
const GuestSettings = Loadable(lazyWithRetry(() => import('../pages/dashboard/GuestSettings')));
const GuestReplyToConfirmation = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/GuestReplyToConfirmation'))
);
const GuestLimit = Loadable(lazyWithRetry(() => import('../pages/dashboard/GuestLimit')));
const GuestLimitEvents = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/GuestLimitEvents'))
);

// NTAK ATTRACTION
const NtakAttractionRegistration = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/NtakAttractionRegistration'))
);
const NtakAttractionList = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/NtakAttractionList'))
);

// NTAK BOX OFFICE
const NtakBoxOfficeEvents = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/NtakBoxOfficeEvents'))
);
const NtakBoxOfficeSettings = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/NtakBoxOfficeSettings'))
);

// STATISTICS
const StatisticsSales = Loadable(lazyWithRetry(() => import('../pages/dashboard/StatisticsSales')));
const StatisticsSalesDetails = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/StatisticsSalesDetails'))
);
const StatisticsReport = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/StatisticsReport'))
);
const StatisticsCustomized = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/StatisticsCustomized'))
);
const StatisticsCustomizedCategory = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/StatisticsCustomizedCategory'))
);
const StatisticsReportSubscription = Loadable(
  lazyWithRetry(() => import('../pages/dashboard/StatisticsReportSubscription'))
);

// INVITED USER
const UserInvite = Loadable(lazyWithRetry(() => import('../pages/dashboard/UserInvite')));
const UserList = Loadable(lazyWithRetry(() => import('../pages/dashboard/UserList')));

// GENERAL
const UserHistory = Loadable(lazyWithRetry(() => import('../pages/dashboard/UserHistory')));
const Offer = Loadable(lazyWithRetry(() => import('../pages/dashboard/Offer')));
const Recommendation = Loadable(lazyWithRetry(() => import('../pages/dashboard/Recommendation')));
const NotFound = Loadable(lazyWithRetry(() => import('../pages/Page404')));
