import { Theme } from '@mui/material/styles';
import {
  Box,
  SxProps,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from '@mui/material';
import { TableEnum } from './type';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

type Props = {
  table: TableEnum;
  order?: 'asc' | 'desc';
  orderBy?: string;
  headLabel: any[];
  rowCount?: number;
  numSelected?: number;
  dense?: boolean;
  onSort?: (id: string, table: TableEnum) => void;
  onSelectAllRows?: (checked: boolean) => void;
  sx?: SxProps<Theme>;
};

export default function TableHeadCustom({
  table,
  order,
  orderBy,
  rowCount = 0,
  headLabel,
  numSelected = 0,
  dense = false,
  onSort,
  onSelectAllRows,
  sx,
}: Props) {
  return (
    <TableHead sx={sx}>
      <TableRow>
        {onSelectAllRows && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSelectAllRows(event.target.checked)
              }
            />
          </TableCell>
        )}

        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.width,
              minWidth: headCell.minWidth,
              paddingLeft: dense ? 0 : 2,
              whiteSpace: headCell.whiteSpace,
            }}
          >
            {onSort && headCell.sortable ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onSort(headCell.id, table)}
              >
                {headCell.icon ? (
                  <Box display="flex" alignItems="center" columnGap={0.5}>
                    {headCell.label}
                    {headCell.icon}
                  </Box>
                ) : (
                  <>{headCell.label}</>
                )}

                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <>
                {headCell.icon ? (
                  <Box display="flex" alignItems="center" columnGap={0.5}>
                    {headCell.label}
                    {headCell.icon}
                  </Box>
                ) : (
                  <>{headCell.label}</>
                )}
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
